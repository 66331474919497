<template>
  <div>
    <router-link
      :to="{
        name: 'sheet-edit',
        query: {
          filterByJournalId: $route.query.filterByJournalId,
          dayId: $route.query.dayId,
          listId: $route.query.listId,
        },
      }"
      class="bordered-item mb-3"
      >Назад</router-link
    >

    <CustomForm
      title="Задания"
      @create="create"
      @remove="remove"
      :saveIsDisable="v$.name.$invalid || saveInProcess"
    >
      <div
        :class="{
          error: v$.name.$errors.length,
          success: !v$.name.$errors.length && v$.name.$model && v$.name.$dirty,
        }"
        class="form__item"
      >
        <p class="form__label">№ задания</p>

        <input
          v-model="v$.name.$model"
          type="text"
          @focus="v$.name.$touch"
          placeholder="Введите номер задания"
          maxlength="100"
          class="form__input"
        />

        <div
          :class="{ 'showing-tip': v$.name.$dirty && v$.name.$model.length }"
          class="form__tip-wrapper"
        >
          <div class="form__tip form__tip--counter showing">
            <span>Любые символы </span>
            <span> {{ v$.name.$model.length }} / 100 </span>
          </div>
        </div>

        <div
          class="input-errors"
          v-for="error of v$.name.$errors"
          :key="error.$uid"
        >
          <div class="error-msg form__tip form__tip--error showing">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div v-if="currentTask" class="form__item">
        <p class="form__label">Уровень</p>
        <CompactSelect :options="options" v-model="level" />
      </div>

      <div v-if="currentTask" class="form__item">
        <p class="form__label">Компетенции</p>
        <ChildCompetenceEditor v-model="competence" />
      </div>
    </CustomForm>
  </div>
</template>

<script setup>
import { useSubjectsJournalStore } from "@/store/subjects-journal";
import { useRoute, useRouter } from "vue-router";

import { computed, onMounted, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

import ChildCompetenceEditor from "@/components/ChildCompetenceEditor.vue";
import CustomForm from "@/components/CustomForm.vue";
import CompactSelect from "@/elements/CompactSelect.vue";

import { customAxios } from "@/service/customAxios";
import { usePopupStore } from "@/store/popup";
import { storeToRefs } from "pinia";

import { compareArrays, debounce } from "@/util/common";
import { useJournalData } from "@/composables/journalData";

const { updateRatingSheets } = useJournalData();

onMounted(updateRatingSheets);

const debouncedEditName = debounce(edit, 1000);

const options = [
  { value: 1, title: "1" },
  { value: 2, title: "2" },
  { value: 3, title: "3" },
];

const rules = {
  name: {
    required: helpers.withMessage("Обязательное поле", required),
    max: helpers.withMessage("Не более 100 символов", maxLength(100)),
  },
};

const route = useRoute();
const router = useRouter();
const { closePopup, showToastPopup, showError, showConfirmPopup } =
  usePopupStore();
const { ratingSheets } = storeToRefs(useSubjectsJournalStore());

const sheet = computed(() => {
  if (!ratingSheets.value.length) {
    return null;
  }

  return ratingSheets.value.find((v) => v.id === Number(route.query.listId));
});

const currentTask = computed(() => {
  if (!sheet.value || !sheet.value.skillListSkills.length) {
    return null;
  }

  return sheet.value.skillListSkills.find(
    (v) => v.id === Number(route.query.taskId)
  );
});

const name = ref("");
const level = ref(1);
const competence = ref([]);
const saveInProcess = ref(false);

const v$ = useVuelidate(rules, {
  name: name,
});

function setCurrentData() {
  if (!currentTask.value) {
    return;
  }

  name.value = currentTask.value.skillDescription;
  level.value = currentTask.value.skillLevel;
  competence.value = currentTask.value.competenceList
}

async function create() {
  console.log("Save pushed")

  try {
    saveInProcess.value=true;
    await createHandler();
    showToastPopup("Сохранено");
    back();
  } catch (e) {
    showError(e);
  }
}

async function createHandler() {
  return customAxios.post(`/teacher/log/skill_list/skill`, {
    skillListId: sheet.value.id,
    skillDescription: name.value,
    competenceList: competence.value,
    skillLevel: level.value,
  });
}

watch(currentTask, setCurrentData);
watch(name, (n) => {

  if (!n.length || (currentTask.value && currentTask.value.skillDescription && n === currentTask.value.skillDescription )) {
    return;
  }

  debouncedEditName();
});

watch(level, (l) => {
  if (l === currentTask.value.skillLevel) {
    return;
  }

  edit();
});

watch(competence, (c) => {
  if (compareArrays(c, currentTask.value.competenceList)) {
    return
  }
  edit()
}, { deep: true });

async function edit() {
  if (!route.query.taskId || v$.value.$invalid) {
    return;
  }
  try {
    await editHandler();
    showToastPopup("Сохранено");
    await updateRatingSheets()
  } catch (e) {
    showError(e);
  }
}

async function editHandler() {
  return customAxios.put(
    `/teacher/log/skill_list/skill/${route.query.taskId}`,
    {
      skillDescription: name.value,
      competenceList: competence.value,
      skillLevel: level.value,
    }
  );
}

async function remove() {
  try {
    const res = await customConfirm();

    if (res === "no") {
      closePopup();
      return;
    }

    closePopup();
    await removeTask();
    showToastPopup("Задание удалено");
    back();
  } catch (e) {
    showError(e);
  }
}

function back() {
  router.push({
    name: "sheet-edit",
    query: {
      filterByJournalId: route.query.filterByJournalId,
      listId: route.query.listId,
      dayId: route.query.dayId,
    },
  });
}

async function customConfirm() {
  return showConfirmPopup("Удалить Задание");
}

async function removeTask() {
  return customAxios.delete(
    `/teacher/log/skill_list/skill/${route.query.taskId}`
  );
}
</script>
